import { NavLink } from "react-router-dom";
import LayoutHome from "../../layouts/LayoutHome";
import CharacterDisplay from "../../partials/game/CharacterDisplay";
import GameFeature from "../../partials/game/GameFeature";
import Khugaverse from "../../partials/game/Khugaverse";
import Footer from "../../partials/Footer";

export default function GameHomePage() {
  return (
    <LayoutHome background="bg-white">
      <div className="pt-16">
        {/* Story */}
        <section
          className="md:hidden w-full aspect-[9/16] bg-no-repeat bg-cover bg-center flex items-start justify-center"
          style={{ backgroundImage: `url('/images/game/bg-mobile-story.webp')` }}
        >
          <div className="p-8 font-bold font-marketplace text-center text-2xl pt-12">
            Welcome to the Khugaverse, a dynamic digital ecosystem where gaming meets innovation and community. Built on the principles of creativity, collaboration, and player empowerment, the Khugaverse offers a seamless blend of thrilling games, collectible NFTs, and rewarding experiences. Whether you’re a gamer, collector, or enthusiast, the Khugaverse invites you to join an ever-evolving journey where fun meets meaningful engagement.
          </div>
        </section>

        <section
          className="w-full aspect-[10/7] bg-no-repeat bg-cover bg-center text-xl hidden md:flex items-center justify-center"
          style={{ backgroundImage: `url('/images/game/bg-story.webp')` }}
        >
          <div className="px-4 w-[40%] aspect-[5/3] mt-10 font-bold text-center font-marketplace text-[#00b57f] flex items-center justify-center" style={{ fontSize: `2vw` }}>
            Welcome to the Khugaverse, a dynamic digital ecosystem where gaming meets innovation and community. Built on the principles of creativity, collaboration, and player empowerment, the Khugaverse offers a seamless blend of thrilling games, collectible NFTs, and rewarding experiences. Whether you’re a gamer, collector, or enthusiast, the Khugaverse invites you to join an ever-evolving journey where fun meets meaningful engagement.
          </div>
        </section>

        {/* All characters */}
        <section
          className="md:hidden w-full aspect-[9/16] bg-no-repeat bg-cover bg-center relative"
          style={{ backgroundImage: `url('/images/game/bg-mobile-5-character.webp')` }}
        >
          <div className="text-center font-cursive pt-[25%] px-10 text-black">
            <h2 className="mb-2 font-bold" style={{ fontSize: `5vw` }}>PLAYABLE CHARACTER</h2>
            <p className="font-marketplace"  style={{ fontSize: `4vw` }}>
              There are 5 playable characters with different attributes, skills, and roles that can be utilized uniquely. Each character also has a set of weapons that align with their base role. Specifically for Khuga NFT holders, you can play character sets according to the NFTs you own.
            </p>
            <NavLink
              to={`${process.env.PUBLIC_URL}/map`}
              className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all mt-2 block w-44"
            >
              <img src={`${process.env.PUBLIC_URL}/images/navbar/menu.webp`} alt="Menu" className="h-16" />
              <span className={`absolute top-0 left-0 w-full text-center mt-2.5 text-sm font-cursive text-white pt-2.5`}>
                See the full map
              </span>
            </NavLink>
          </div>
        </section>

        <section
          className="hidden md:block w-full aspect-[10/7] bg-no-repeat bg-cover bg-center relative"
          style={{ backgroundImage: `url('/images/game/bg-5-character.webp')` }}
        >
          <div className="absolute top-[8%] left-[6%] px-4 aspect-[5/3] mt-10 font-medium font-cursive text-black w-[40%]">
            <h2 className="mb-2 font-bold" style={{ fontSize: `2.4vw` }}>PLAYABLE CHARACTER</h2>
            <p className="font-marketplace" style={{ fontSize: `2vw` }}>
              There are 5 playable characters with different attributes, skills, and roles that can be utilized uniquely. Each character also has a set of weapons that align with their base role. Specifically for Khuga NFT holders, you can play character sets according to the NFTs you own.
            </p>
            <NavLink
              to={`${process.env.PUBLIC_URL}/map`}
              className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all mt-2 block w-44"
            >
              <img src={`${process.env.PUBLIC_URL}/images/navbar/menu.webp`} alt="Menu" className="h-16" />
              <span className={`absolute top-0 left-0 w-full text-center mt-2.5 text-sm font-cursive text-white pt-2.5`}>
                See the full map
              </span>
            </NavLink>
          </div>
        </section>

        {/* Character Display */}
        <CharacterDisplay />

        {/* GameFeature */}
        {/* <GameFeature /> */}

        <Khugaverse />
      </div>

      <Footer />
    </LayoutHome>
  );
}
