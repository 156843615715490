import { useEffect, useState } from "react";
import { animated, useSprings } from "@react-spring/web";

const stats = [
  'Atk Speed',
  'Damage',
  'Range',
  'HP',
  'Speed',
  'Weapon ',
]
const characters = [
  {
    id: 1,
    name: "kuro",
    display_name: "Kuro",
    stats: [1, 2, 3, 4, 5, 4],
    desc: "An impulsive fighter who charges into battle without thinking about the consequences.",
  },
  {
    id: 2,
    name: "spinx",
    display_name: "Spin-X",
    stats: [5, 0, 1, 1, 3, 2],
    desc: "Attacks with poison or magic until the enemy can't tell what hit them.",
  },
  {
    id: 3,
    name: "shiro",
    display_name: "Shiro",
    stats: [5, 4, 3, 4, 5, 4],
    desc: "A loyal fighter with a high fighting spirit, ready to fight by your side no matter what.",
  },
  {
    id: 4,
    name: "saber",
    display_name: "Saber",
    stats: [5, 2, 3, 1, 3, 4],
    desc: "Slow but slash! Silent but slash! Sword, specifically katana, is their weapon of choice.",
  },
  {
    id: 5,
    name: "fatp",
    display_name: "Fat-P",
    stats: [4, 2, 2, 2, 3, 2],
    desc: "Food lover who loves to laze around. Don't bother him unless you're ready to get hurt.",
  },
];

export default function CharacterDisplay() {
  const [active, setActive] = useState(0);

  const [springs, api] = useSprings(
    characters.length,
    (i) => ({
      from: {
        x: i === 0 ? 0 : i === 1 ? -400 : i === 2 ? -220 : i === 3 ? 220 : i === 4 ? 400 : 0,
        scale: i === 0 ? 1 : i === 1 || i === 4 ? 0.6 : i === 2 || i === 3 ? 0.4 : 1,
        zIndex: i === 0 ? 5 : i === 1 ? 4 : i === 2 ? 3 : i === 3 ? 2 : i === 4 ? 1 : 5,
      },
      to: {
        x: i === 0 ? 0 : i === 1 ? -400 : i === 2 ? -220 : i === 3 ? 220 : i === 4 ? 400 : 0,
        scale: i === 0 ? 1 : i === 1 || i === 4 ? 0.6 : i === 2 || i === 3 ? 0.4 : 1,
        zIndex: i === 0 ? 5 : i === 1 ? 4 : i === 2 ? 3 : i === 3 ? 2 : i === 4 ? 1 : 5,
      },
    }),
    []
  );

  useEffect(() => {
    if (active === 0) {
      api.start((i) => {
        return {
          immediate: true,
          from: {
            zIndex: springs[i].zIndex.animation.to,
          },
          to: {
            zIndex: i === 0 ? 5 : i === 1 ? 4 : i === 2 ? 3 : i === 3 ? 2 : i === 4 ? 4 : 5,
          },
        };
      });

      api.start((i) => {
        return {
          from: {
            x: springs[i].x.animation.to,
            scale: springs[i].scale.animation.to,
          },
          to: {
            x: i === 0 ? 0 : i === 1 ? -400 : i === 2 ? -220 : i === 3 ? 220 : i === 4 ? 400 : 0,
            scale: i === 0 ? 1 : i === 1 ? 0.6 : i === 2 ? 0.4 : i === 3 ? 0.4 : i === 4 ? 0.6 : 1,
          },
        };
      });
    }

    if (active === 1) {
      api.start((i) => {
        return {
          immediate: true,
          from: {
            zIndex: springs[i].zIndex.animation.to,
          },
          to: {
            zIndex: i === 0 ? 4 : i === 1 ? 5 : i === 2 ? 4 : i === 3 ? 3 : i === 4 ? 2 : 1,
          },
        };
      });
      api.start((i) => {
        return {
          from: {
            x: springs[i].x.animation.to,
            scale: springs[i].scale.animation.to,
          },
          to: {
            x: i === 0 ? 400 : i === 1 ? 0 : i === 2 ? -400 : i === 3 ? -220 : i === 4 ? 220 : 400,
            scale: i === 0 ? 0.6 : i === 1 ? 1 : i === 2 ? 0.6 : i === 3 ? 0.4 : i === 4 ? 0.4 : 0.6,
          },
        };
      });
    }

    if (active === 2) {
      api.start((i) => {
        return {
          immediate: true,
          from: {
            zIndex: springs[i].zIndex.animation.to,
          },
          to: {
            zIndex: i === 0 ? 2 : i === 1 ? 4 : i === 2 ? 5 : i === 3 ? 4 : i === 4 ? 3 : 2,
          },
        };
      });
      api.start((i) => {
        return {
          from: {
            x: springs[i].x.animation.to,
            scale: springs[i].scale.animation.to,
          },
          to: {
            x: i === 0 ? 220 : i === 1 ? 400 : i === 2 ? 0 : i === 3 ? -400 : i === 4 ? -220 : 220,
            scale: i === 0 ? 0.4 : i === 1 ? 0.6 : i === 2 ? 1 : i === 3 ? 0.6 : i === 4 ? 0.4 : 0.4,
          },
        };
      });
    }

    if (active === 3) {
      api.start((i) => {
        return {
          immediate: true,
          from: {
            zIndex: springs[i].zIndex.animation.to,
          },
          to: {
            zIndex: i === 0 ? 3 : i === 1 ? 2 : i === 2 ? 4 : i === 3 ? 5 : i === 4 ? 4 : 3,
          },
        };
      });
      api.start((i) => {
        return {
          from: {
            x: springs[i].x.animation.to,
            scale: springs[i].scale.animation.to,
          },
          to: {
            x: i === 0 ? -220 : i === 1 ? 220 : i === 2 ? 400 : i === 3 ? 0 : i === 4 ? -400 : -220,
            scale: i === 0 ? 0.4 : i === 1 ? 0.4 : i === 2 ? 0.6 : i === 3 ? 1 : i === 4 ? 0.6 : 0.4,
          },
        };
      });
    }

    if (active === 4) {
      api.start((i) => {
        return {
          immediate: true,
          from: {
            zIndex: springs[i].zIndex.animation.to,
          },
          to: {
            zIndex: i === 0 ? 4 : i === 1 ? 3 : i === 2 ? 2 : i === 3 ? 4 : i === 4 ? 5 : 4,
          },
        };
      });
      api.start((i) => {
        return {
          from: {
            x: springs[i].x.animation.to,
            scale: springs[i].scale.animation.to,
          },
          to: {
            x: i === 0 ? -400 : i === 1 ? -220 : i === 2 ? 220 : i === 3 ? 400 : i === 4 ? 0 : -400,
            scale: i === 0 ? 0.6 : i === 1 ? 0.4 : i === 2 ? 0.4 : i === 3 ? 0.6 : i === 4 ? 1 : 0.6,
          },
        };
      });
    }
  }, [active]);

  const handleNext = () => {
    if (active === 0) {
      return setActive(4);
    }

    if (active <= characters.length) {
      return setActive((prev) => prev - 1);
    }
  };

  const handlePrev = () => {
    if (active === 4) {
      return setActive(0);
    }

    if (active < characters.length) {
      return setActive((prev) => prev + 1);
    }
  };

  return (
    <section
      className="w-full aspect-[9/16] md:aspect-[10/7] bg-no-repeat bg-cover bg-center relative"
      style={{ backgroundImage: `url('/images/game/bg-display-character.webp')` }}
    >
      {/* Title */}
      <div
        className="h-14 md:h-20 aspect-[6/1] bg-contain bg-center bg-no-repeat mx-auto top-20 relative flex items-center justify-center"
        style={{ backgroundImage: `url('/images/game/display/bg-title.webp')` }}
      >
        <h2 className="font-bold text-3xl md:text-4xl text-black font-cursive">CHARACTER INFO</h2>
      </div>

      {/* Main Character */}
      <div className="max-w-7xl mx-auto mt-32 relative">
        <div className="w-full aspect-[7/3] bg-red-500/0 relative overflow-hidden">
          {springs.map(({ x, y, scale, zIndex }, i) => (
            <animated.div
              style={{
                translateX: x,
                translateY: y,
                scale: scale,
                zIndex: zIndex,
              }}
              className="h-full aspect-video mx-auto flex items-center justify-center absolute inset-x-0"
            >
              <img src={`/images/game/character/${characters[i].name}.webp`} alt="" className="h-full object-cover" />
            </animated.div>
          ))}
        </div>

        {/* Name & Nav */}
        <div className="absolute bottom-0 inset-x-0 z-20">
          <div className="flex items-center justify-center gap-1">
            <button onClick={handlePrev} className="hover:scale-105 active:scale-90 transition-all">
              <img src="/images/game/display/arrow-left.webp" alt="Arrow left" className="h-10 md:h-14" />
            </button>
            <div
              className="h-10 md:h-14 aspect-[6/1] bg-contain bg-center bg-no-repeat relative flex items-center justify-center"
              style={{ backgroundImage: `url('/images/game/display/bg-name.webp')` }}
            >
              <h2 className="font-bold text-2xl md:text-4xl text-black font-cursive">{characters[active].display_name}</h2>
            </div>
            <button onClick={handleNext} className="hover:scale-105 active:scale-90 transition-all">
              <img src="/images/game/display/arrow-right.webp" alt="Arrow right" className="h-10 md:h-14" />
            </button>
          </div>
        </div>
      </div>

      {/* Character Detail */}
      <div className="hidden md:block max-w-5xl mx-auto mt-11 relative">
        <div className="w-full aspect-[5/1] bg-contain px-2" style={{ backgroundImage: `url('/images/game/display/bg-detail.webp')` }}>
          <div className="w-full h-full flex items-center gap-10">
            <div className="flex-1 h-full flex items-center justify-center">
              <h2 className="font-bold font-cursive uppercase text-black absolute top-1 left-4">STATS</h2>
              <div className="w-5/6 mt-[6%] space-y-px">
                {characters[active].stats.map((i, idx) => (
                  <div className="flex items-center gap-4 font-bold text-black font-cursive text-sm">
                    <p className="shrink-0 w-28">{stats[idx]}</p>
                    <div className="flex-1 grid grid-cols-5">
                      <div className={`w-full h-4 border-4 border-black ${i >= 1 && "bg-green-500"}`}></div>
                      <div className={`w-full h-4 border-4 border-black ${i >= 2 && "bg-green-500"}`}></div>
                      <div className={`w-full h-4 border-4 border-black ${i >= 3 && "bg-green-500"}`}></div>
                      <div className={`w-full h-4 border-4 border-black ${i >= 4 && "bg-green-500"}`}></div>
                      <div className={`w-full h-4 border-4 border-black ${i >= 5 && "bg-green-500"}`}></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="shrink-0 w-[15.7%] aspect-[19/20] -translate-y-2.5">
              <img src={`/images/game/thumb/${characters[active].name}.webp`} alt="" />
            </div>
            <div className="flex-1 h-full">
              <h2 className="font-bold font-cursive uppercase text-black absolute bottom-2 right-4">INFO</h2>
              <p className="font-bold font-marketplace text-lg md:text-xl lg:text-2xl text-black mx-3 my-5">{characters[active].desc}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
