export default function Khugaverse() {
  return (
    <section
      className="w-full bg-no-repeat bg-cover bg-center relative"
      style={{ backgroundImage: `url('/images/game/bg-feature.webp')` }}
    >
      <div className="absolute inset-0 bg-black/80 backdrop-blur"></div>
      <div className="container mx-auto px-4 py-24 relative">
        <h2 className="font-bold text-3xl xl:text-3xl mb-8 text-center text-white font-cursive">Our Products</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          <a href="https://opensea.io/collection/khuga-by-khuga-labs" className="w-full border-2 border-white/50 hover:border-[#BDFF00] shadow-2xl px-8 py-4 text-white rounded-xl relative group overflow-hidden">
            <img src="/images/product/khuga-nft.webp" alt="Khuga NFT" className="w-28 md:w-32 lg:w-40" />
            <p className="font-marketplace text-xl xl:text-2xl mb-6">Lorem ipsum dolor sit amet consectetur adipisicing elit. A accusantium harum soluta</p>

            <div className="absolute top-4 -right-20 group-hover:right-4 transition-all">
              <div className="w-10 h-10 bg-[#BDFF00] rounded-full flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-5 text-black">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                </svg>
              </div>
            </div>
          </a>
          <a href="#" className="w-full border-2 border-white/50 hover:border-[#BDFF00] shadow-2xl px-8 py-4 text-white rounded-xl relative group overflow-hidden">
            <img src="/images/product/khuga-bash.webp" alt="Khuga Bash" className="w-28 md:w-32 lg:w-40" />
            <p className="font-marketplace text-xl xl:text-2xl mb-6">Lorem ipsum dolor sit amet consectetur adipisicing elit. A accusantium harum soluta</p>

            <div className="absolute top-4 -right-20 group-hover:right-4 transition-all">
              <div className="w-10 h-10 bg-[#BDFF00] rounded-full flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-5 text-black">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                </svg>
              </div>
            </div>
          </a>
          <a href="#" className="w-full border-2 border-white/50 hover:border-[#BDFF00] shadow-2xl px-8 py-4 text-white rounded-xl relative group overflow-hidden">
            <img src="/images/product/khuga-rumble.webp" alt="Khuga Rumble Arena" className="w-28 md:w-32 lg:w-40" />
            <p className="font-marketplace text-xl xl:text-2xl mb-6">Lorem ipsum dolor sit amet consectetur adipisicing elit. A accusantium harum soluta</p>

            <div className="absolute top-4 -right-20 group-hover:right-4 transition-all">
              <div className="w-10 h-10 bg-[#BDFF00] rounded-full flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-5 text-black">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                </svg>
              </div>
            </div>
          </a>
          <a href="/auth" className="w-full border-2 border-white/50 hover:border-[#BDFF00] shadow-2xl px-8 py-4 text-white rounded-xl relative group overflow-hidden">
            <img src="/images/product/miawwhalla.webp" alt="Miawwhalla" className="w-28 md:w-32 lg:w-40" />
            <p className="font-marketplace text-xl xl:text-2xl mb-6">Lorem ipsum dolor sit amet consectetur adipisicing elit. A accusantium harum soluta</p>

            <div className="absolute top-4 -right-20 group-hover:right-4 transition-all">
              <div className="w-10 h-10 bg-[#BDFF00] rounded-full flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-5 text-black">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                </svg>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}
