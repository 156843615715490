import React, { useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Transition } from "@headlessui/react";

function LayoutHome(props) {
  const { pathname } = useLocation();
  const [navOpen, setNavOpen] = useState(false);

  return (
    <>
      <nav
        className={`fixed top-0 inset-x-0 z-20 h-16 md:h-[4.75rem] bg-cover bg-bottom ${pathname.includes("marketplace") ? "p-2 bg-transparent md:p-0 md:bg-white/70" : "bg-white/70"}`}
      >
        <div className="w-full mx-auto px-4 md:px-10 relative">
          <div className="relative z-20 flex justify-between space-x-2 md:space-x-8 items-center">
            <NavLink to={`${process.env.PUBLIC_URL}/`}>
              <img src={`${process.env.PUBLIC_URL}/images/logo/logo-green.webp`} className="h-14 md:h-[4.25rem]" alt="Logo" />
            </NavLink>
            <div className="relative flex justify-end space-x-4 top-1">
              {/* Menu */}
              <div className="hidden lg:flex space-x-3">
                <NavLink
                  to={`${process.env.PUBLIC_URL}/`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu.webp`} alt="Menu" className="h-10" />
                  <span
                    className={`absolute top-0 w-full text-center mt-2.5 text-xs font-cursive ${pathname === "/" ? "text-[#BDFF00]" : "text-white"}`}
                  >
                    Home
                  </span>
                </NavLink>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/auth`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu.webp`} alt="Menu" className="h-10" />
                  <span className={`absolute top-0 w-full text-center mt-2.5 text-xs font-cursive ${pathname === "/auth" ? "text-[#BDFF00]" : "text-white"}`}>Miawwhalla</span>
                </NavLink>
                {/* <NavLink
                  to={`${process.env.PUBLIC_URL}/marketplace`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu.webp`} alt="Menu" className="h-10" />
                  <span
                    className={`absolute top-0 w-full text-center mt-2.5 text-xs font-cursive ${pathname === "/marketplace" ? "text-black" : "text-white"}`}
                  >
                    Marketplace
                  </span>
                </NavLink> */}
                {/* <a href="#" className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all">
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu.webp`} alt="Menu" className="h-10" />
                  <span className="absolute top-0 w-full text-center mt-2.5 text-sxstext-white font-cursive">MIU Holder</span>
                </a> */}
                {/* <NavLink
                  to={`${process.env.PUBLIC_URL}/about`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu.webp`} alt="Menu" className="h-10" />
                  <span className={`absolute top-0 w-full text-center mt-2.5 text-xs font-cursive ${pathname === "/about" ? "text-black" : "text-white"}`}>
                    About
                  </span>
                </NavLink>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/roadmap`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu.webp`} alt="Menu" className="h-10" />
                  <span className={`absolute top-0 w-full text-center mt-2.5 text-xs font-cursive ${pathname === "/roadmap" ? "text-black" : "text-white"}`}>
                    Road Map
                  </span>
                </NavLink> */}
                {/* <NavLink
                  to={`${process.env.PUBLIC_URL}/gallery`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu.webp`} alt="Menu" className="h-10" />
                  <span className={`absolute top-0 w-full text-center mt-2.5 text-xs font-cursive ${pathname === "/gallery" ? "text-black" : "text-white"}`}>
                    Gallery
                  </span>
                </NavLink> */}
                {/* <NavLink
                  to={`${process.env.PUBLIC_URL}/token`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu.webp`} alt="Menu" className="h-10" />
                  <span
                    className={`absolute top-0 w-full text-center mt-2.5 text-xs font-cursive ${pathname === "/token" ? "text-black" : "text-white"}`}
                  >
                    Khuga Token
                  </span>
                </NavLink> */}
                {/* <NavLink
                  to={`${process.env.PUBLIC_URL}/game`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu.webp`} alt="Menu" className="h-10" />
                  <span
                    className={`absolute top-0 w-full text-center mt-2.5 text-xs font-cursive ${pathname === "/game" ? "text-black" : "text-white"}`}
                  >
                    Khuga Game
                  </span>
                </NavLink> */}
                <NavLink
                  to={`${process.env.PUBLIC_URL}/labs`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu.webp`} alt="Menu" className="h-10" />
                  <span
                    className={`absolute top-0 w-full text-center mt-2.5 text-xs font-cursive ${pathname === "/labs" ? "text-[#BDFF00]" : "text-white"}`}
                  >
                    Khuga Labs
                  </span>
                </NavLink>
              </div>
              {/* Social Media */}
              <div className="hidden lg:flex space-x-3">
                <a href="https://discord.com/invite/khuga" target="_blank" className="hover:contrast-200">
                  <img src={`${process.env.PUBLIC_URL}/images/social/discord.svg`} className="h-10" alt="Discord" />
                </a>
                <a href="https://twitter.com/khuganft" target="_blank" className="hover:contrast-200">
                  <img src={`${process.env.PUBLIC_URL}/images/social/twitter.svg`} className="h-10" alt="Twitter" />
                </a>
                <a href="https://opensea.io/collection/khuga-by-khuga-labs" target="_blank" className="hover:contrast-200">
                  <img src={`${process.env.PUBLIC_URL}/images/social/opensea.svg`} className="h-10" alt="Opensea" />
                </a>
                {/* <a href="#" className="hover:brightness-200">
                  <img src={`${process.env.PUBLIC_URL}/images/social/me.svg`} className="h-10" alt="ME" />
                </a> */}
              </div>
              {/* <img src={`${process.env.PUBLIC_URL}/images/navbar/scratch.svg`} alt="" className="absolute -right-10 -top-6 h-24" /> */}
            </div>
            <div className="flex lg:hidden">
              {/* <img src={`${process.env.PUBLIC_URL}/images/navbar/scratch.svg`} alt="" className="absolute h-20 sm:h-24 right-20 -top-6" /> */}
              <a className="relative hover:opacity-80 hover:shadow transition-all cursor-pointer" onClick={() => setNavOpen((navOpen) => !navOpen)}>
                {pathname.includes("marketplace") ? (
                  <img src={`${process.env.PUBLIC_URL}/images/marketplace/icon-menu.webp`} alt="Menu" className="h-11" />
                ) : (
                  <>
                    <img src={`${process.env.PUBLIC_URL}/images/navbar/menu-square.svg`} alt="Menu" className="h-11" />
                    {navOpen ? (
                      <span className="absolute top-0.5 left-2 w-full text-center mt-1 text-white font-cursive">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={3}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </span>
                    ) : (
                      <span className="absolute top-0.5 left-2 w-full text-center mt-1 text-white font-cursive">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={3}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                      </span>
                    )}
                  </>
                )}
              </a>
            </div>
          </div>
        </div>
        <Transition
          show={navOpen}
          enter="transition ease-out duration-150"
          enterFrom="opacity-0 scale-90"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-90"
          className="absolute inset-x-0 top-16 z-10"
        >
          <div className="bg-white/70 h-screen p-4 pt-12 block lg:hidden">
            <div className="space-y-3">
              <div className="flex justify-center items-center">
                <NavLink
                  to={`${process.env.PUBLIC_URL}/`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu-mobile.webp`} alt="Menu" className="h-14 mx-auto" />
                  <span
                    className={`absolute top-0 w-full text-center mt-3.5 text-white font-cursive ${
                      pathname === "/" ? "text-[#BDFF00]" : "text-white"
                    }`}
                  >
                    Home
                  </span>
                </NavLink>
              </div>
              {/* <div className="flex justify-center items-center">
                <NavLink
                  to={`${process.env.PUBLIC_URL}/auth`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu-mobile.webp`} alt="Menu" className="h-14 mx-auto" />
                  <span className="absolute top-0 w-full text-center mt-3.5 text-[#BDFF00] font-cursive animate-pulse">Connect</span>
                </NavLink>
              </div> */}
              {/* <div className="flex justify-center items-center">
                <a href="#" className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all">
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu-mobile.webp`} alt="Menu" className="h-14 mx-auto" />
                  <span className="absolute top-0 w-full text-center mt-3.5 text-white font-cursive">MIU Holder</span>
                </a>
              </div> */}
              <div className="flex justify-center items-center">
                <NavLink
                  to={`${process.env.PUBLIC_URL}/miawwhalla`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu-mobile.webp`} alt="Menu" className="h-14 mx-auto" />
                  <span
                    className={`absolute top-0 w-full text-center mt-3.5 text-white font-cursive ${
                      pathname === "/miawwhalla" ? "text-[#BDFF00]" : "text-white"
                    }`}
                  >
                    Miawwhalla
                  </span>
                </NavLink>
              </div>
              {/* <div className="flex justify-center items-center">
                <NavLink
                  to={`${process.env.PUBLIC_URL}/marketplace`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu-mobile.webp`} alt="Menu" className="h-14 mx-auto" />
                  <span
                    className={`absolute top-0 w-full text-center mt-3.5 text-white font-cursive ${
                      pathname === "/marketplace" ? "text-[#BDFF00]" : "text-white"
                    }`}
                  >
                    Marketplace
                  </span>
                </NavLink>
              </div> */}
              {/* <div className="flex justify-center items-center">
                <NavLink
                  to={`${process.env.PUBLIC_URL}/about`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu-mobile.webp`} alt="Menu" className="h-14 mx-auto" />
                  <span
                    className={`absolute top-0 w-full text-center mt-3.5 text-white font-cursive ${pathname === "/about" ? "text-[#BDFF00]" : "text-white"}`}
                  >
                    About
                  </span>
                </NavLink>
              </div>
              <div className="flex justify-center items-center">
                <NavLink
                  to={`${process.env.PUBLIC_URL}/roadmap`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu-mobile.webp`} alt="Menu" className="h-14 mx-auto" />
                  <span
                    className={`absolute top-0 w-full text-center mt-3.5 text-white font-cursive ${pathname === "/roadmap" ? "text-[#BDFF00]" : "text-white"}`}
                  >
                    Road Map
                  </span>
                </NavLink>
              </div> */}
              {/* <div className="flex justify-center items-center">
                <NavLink
                  to={`${process.env.PUBLIC_URL}/gallery`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu-mobile.webp`} alt="Menu" className="h-14 mx-auto" />
                  <span
                    className={`absolute top-0 w-full text-center mt-3.5 text-white font-cursive ${pathname === "/gallery" ? "text-[#BDFF00]" : "text-white"}`}
                  >
                    Gallery
                  </span>
                </NavLink>
              </div> */}
              {/* <div className="flex justify-center items-center">
                <NavLink
                  to={`${process.env.PUBLIC_URL}/token`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu-mobile.webp`} alt="Menu" className="h-14 mx-auto" />
                  <span
                    className={`absolute top-0 w-full text-center mt-3.5 text-white font-cursive ${pathname === "/token" ? "text-[#BDFF00]" : "text-white"}`}
                  >
                    Khuga Token
                  </span>
                </NavLink>
              </div> */}
              {/* <div className="flex justify-center items-center">
                <NavLink
                  to={`${process.env.PUBLIC_URL}/game`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu-mobile.webp`} alt="Menu" className="h-14 mx-auto" />
                  <span
                    className={`absolute top-0 w-full text-center mt-3.5 text-white font-cursive ${pathname === "/game" ? "text-[#BDFF00]" : "text-white"}`}
                  >
                    Khuga Game
                  </span>
                </NavLink>
              </div> */}
              <div className="flex justify-center items-center">
                <NavLink
                  to={`${process.env.PUBLIC_URL}/labs`}
                  className="relative hover:opacity-80 hover:shadow hover:-rotate-2 hover:-translate-y-1 transition-all"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/navbar/menu-mobile.webp`} alt="Menu" className="h-14 mx-auto" />
                  <span
                    className={`absolute top-0 w-full text-center mt-3.5 text-white font-cursive ${pathname === "/labs" ? "text-[#BDFF00]" : "text-white"}`}
                  >
                    Khuga Labs
                  </span>
                </NavLink>
              </div>
            </div>
            {/* Social Media */}
            <div className="flex justify-center mt-6 space-x-3">
              <a href="https://discord.com/invite/khuga" target="_blank" className="hover:contrast-200">
                <img src={`${process.env.PUBLIC_URL}/images/social/discord.svg`} className="h-12" alt="Discord" />
              </a>
              <a href="https://twitter.com/khuganft" target="_blank" className="hover:contrast-200">
                <img src={`${process.env.PUBLIC_URL}/images/social/twitter.svg`} className="h-12" alt="Twitter" />
              </a>
              <a href="https://opensea.io/collection/khuga-by-khuga-labs" target="_blank" className="hover:contrast-200">
                <img src={`${process.env.PUBLIC_URL}/images/social/opensea.svg`} className="h-12" alt="Opensea" />
              </a>
              {/* <a href="#" className="hover:brightness-200">
                <img src={`${process.env.PUBLIC_URL}/images/social/me.svg`} className="h-12" alt="ME" />
              </a> */}
            </div>
          </div>
        </Transition>
      </nav>
      <main className={props.background ?? "bg-white"}>{props.children}</main>
    </>
  );
}

export default LayoutHome;
