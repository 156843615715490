import LayoutHome from "../../layouts/LayoutHome";
import Footer from "../../partials/Footer";

const teams = [
  {id: 1, name: "Gilang Gitarana", title: "Founder & CEO", image: "gilang.webp"},
  {id: 2, name: "James Wong", title: "CTO", image: "james.webp"},
  {id: 3, name: "Indika", title: "COO", image: "indika.webp"},
  {id: 4, name: "Yustin Perdana", title: "Head of Investor Relation", image: "yustin.webp"},
  {id: 5, name: "Stephen De Guzman", title: "CMO", image: "stephen.webp"},
  {id: 6, name: "Tommy N", title: "Lead Engineer", image: "tommy.webp"},
  {id: 7, name: "Fachrizal F", title: "Lead Game Dev", image: "fachrizal.webp"},
  {id: 8, name: "Jeffry A", title: "Collaboration & Partnership Manager", image: "jeffry.webp"},
  {id: 9, name: "Jack Vinijtrongjit", title: "Co-Founder & CEO of Saakuru Labs", image: "jack.webp"},
  {id: 10, name: "Joshua Budiman", title: "Founder & CEO of Sekuya", image: "joshua.webp"},
];

export default function LabsHomePage() {
  return (
    <LayoutHome background="bg-white">
      <div className="">
        {/* Intro */}
        <section
          className="relative w-full aspect-[10/7] bg-no-repeat bg-cover bg-center hidden md:flex items-center justify-center"
          style={{ backgroundImage: `url('/images/labs/bg-intro.webp')` }}
        >
          <div className="absolute top-40 left-10 w-[42%] aspect-[3/2] bg-cover bg-center" style={{ backgroundImage: `url('/images/labs/bg-intro-board.webp')` }}>
            <div className="w-full h-full p-10">
              <h1 className="font-cursive text-black" style={{ fontSize: `3vw` }}>KHUGA LABS</h1>
              <div className="font-marketplace text-black/70 space-y-2" style={{ fontSize: `1.5vw` }}>
                <p>Since 2022, we've been on a mission to expand the Khuga IP as a unique brand, featuring innovative Web3 products, video games, animations, and client-focused services.</p>
                <p>We are visionary innovators, redefining technology and creativity to craft transformative digital experiences.</p>
              </div>
            </div>
          </div>
        </section>
        <section
          className="w-full aspect-[9/16] bg-no-repeat bg-cover bg-center md:hidden flex items-center justify-center"
          style={{ backgroundImage: `url('/images/labs/bg-mobile-intro.webp')` }}
        >
        </section>

        {/* About */}
        {/* Desktop */}
        <section
          className="w-full aspect-[10/7] bg-no-repeat bg-cover bg-right-top hidden md:block relative"
          style={{ backgroundImage: `url('/images/labs/bg-about.webp')` }}
        >
          <div className="absolute inset-x-0 -top-px">
            <img src="/images/labs/divider.webp" alt="Divider" className="w-full" />
          </div>

          <div className="w-full grid grid-cols-7 gap-20 pt-40">
            <div className="col-span-4 pl-10 pr-10">
              <div className="w-5/6 bg-white/40 backdrop-blur p-5 mb-8">
                <h1 className="font-cursive text-black" style={{ fontSize: `2.5vw` }}>TEAM & PARTNER</h1>
                <p className="font-marketplace text-black/70 space-y-2" style={{ fontSize: `1.5vw` }}>Meet the brain power behind our innovative projects and the esteemed partners who help bring our visions to life. We thrive on creativity, professionalism, and a shared passion for excellence.</p>
              </div>

              <div className="w-full bg-cover bg-center aspect-[10/7] pt-[15%] px-[5%] pb-[2%]" style={{ backgroundImage: `url('/images/labs/bg-about-team.webp')` }}>
                <div className="overflow-y-auto w-full h-full">
                  <div className="grid grid-cols-1 xl:grid-cols-2 gap-3">
                    {teams.map(({ id, name, title, image }) => (
                      <div className="flex items-center gap-2" key={id}>
                        <div className="md:w-12 lg:w-14 aspect-square bg-gray-600 rounded-md shrink-0 overflow-hidden border-2 border-black">
                          <img src={`/images/labs/team/${image}`} className="w-full h-full" />
                        </div>
                        <div>
                          <h6 className="font-marketplacebold tracking-wide text-lg text-black mb-0">{name}</h6>
                          <p className="font-marketplace text-black/50 -mt-2">{title}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-3 space-y-12">
              <div className="ml-auto mr-1 w-5/6 aspect-[7/3] bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-about-partner.webp')` }}>
                <img src="/images/labs/partner/saakuru.webp" alt="Saakuru" className="w-1/2 mx-auto" />
              </div>

              <div className="ml-auto mr-1 w-5/6 aspect-[7/3] bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-about-partner.webp')` }}>
                <img src="/images/labs/partner/sekuya.webp" alt="Sekuya" className="w-1/2 mx-auto" />
              </div>

              <div className="ml-auto mr-1 w-5/6 aspect-[7/3] bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-about-partner.webp')` }}>
                <img src="/images/labs/partner/chaosground.webp" alt="Chaos Ground" className="w-1/2 mx-auto" />
              </div>
            </div>
          </div>
        </section>
        {/* Mobile */}
        <section
          className="w-full aspect-[9/16] bg-no-repeat bg-cover bg-center md:hidden relative pt-16 overflow-hidden"
          style={{ backgroundImage: `url('/images/labs/bg-mobile.webp')` }}
        >
          <div className="absolute inset-x-0 -top-px">
            <img src="/images/labs/divider.webp" alt="Divider" className="w-full" />
          </div>

          <div className="ml-9 w-48 aspect-[5/1] flex items-center justify-center font-cursive text-black bg-center bg-cover mb-4" style={{ backgroundImage: `url('/images/labs/bg-mobile-board-title.webp')` }}>
            Team & Partner
          </div>

          <div className="w-full aspect-[9/5] bg-cover bg-left ml-1 pt-[4%] pl-[14%] mb-4" style={{ backgroundImage: `url('/images/labs/bg-mobile-board-team.webp')` }}>
            <div>
              <p className="font-marketplace text-black/70 mb-4 pr-4">Meet the brain power behind our innovative projects and the esteemed partners who help bring our visions to life. </p>

              <div className="py-2 overflow-x-auto flex items-center flex-nowrap gap-4 divide-x divide-black/20">
                {teams.map(({ id, name, title, image }) => (
                  <div className="flex items-center gap-2 flex-1 pl-2" key={id}>
                    <div className="w-14 aspect-square bg-gray-600 rounded-md overflow-hidden border-2 border-black relative shrink-0">
                      <img src={`/images/labs/team/${image}`} className="w-14 aspect-square" />
                    </div>
                    <div className="flex-1 whitespace-nowrap">
                      <h6 className="font-marketplacebold tracking-wide text-lg text-black mb-0">{name}</h6>
                      <p className="font-marketplace text-black/50 -mt-2">{title}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="w-[60%] mb-2 aspect-[7/3] bg-cover bg-left ml-1 flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-mobile-board-partner.webp')` }}>
            <img src="/images/labs/partner/saakuru.webp" alt="Saakuru" className="w-1/2 mb-3 mx-auto" />
          </div>

          <div className="w-[60%] mb-2 aspect-[7/3] bg-cover bg-left ml-1 flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-mobile-board-partner.webp')` }}>
            <img src="/images/labs/partner/sekuya.webp" alt="Saakuru" className="w-1/2 mb-3 mx-auto" />
          </div>

          <div className="w-[60%] mb-2 aspect-[7/3] bg-cover bg-left ml-1 flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-mobile-board-partner.webp')` }}>
            <img src="/images/labs/partner/chaosground.webp" alt="Saakuru" className="w-1/2 mb-3 mx-auto" />
          </div>
        </section>

        {/* Partner */}
        {/* Desktop */}
        <section
          className="w-full aspect-[10/7] bg-no-repeat bg-cover bg-center hidden md:flex items-center justify-center relative"
          style={{ backgroundImage: `url('/images/labs/bg-partner.webp')` }}
        >
          <div className="absolute inset-x-0 -top-px">
            <img src="/images/labs/divider.webp" alt="Divider" className="w-full" />
          </div>

          <div className="max-w-4xl mx-auto px-4">
            <div className="bg-white/40 backdrop-blur p-5 mb-8 mx-auto text-center">
              <h1 className="font-cursive text-black" style={{ fontSize: `2.5vw` }}>OUR PRODUCTS</h1>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 gap-24">
              <div className="w-full aspect-square bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-board-partner.webp')` }}>
                <img src="/images/product/khuga-nft.webp" alt="" className="w-2/3" />
              </div>
              <div className="w-full aspect-square bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-board-partner.webp')` }}>
                <img src="/images/product/miawwhalla.webp" alt="" className="w-2/3" />
              </div>
              <div className="w-full aspect-square bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-board-partner.webp')` }}>
                <img src="/images/product/khuga-bash.webp" alt="" className="w-2/3" />
              </div>
              <div className="w-full aspect-square bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-board-partner.webp')` }}>
                <img src="/images/product/khuga-rumble.webp" alt="" className="w-2/3" />
              </div>
              <div className="w-full aspect-square bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-board-partner.webp')` }}>
                <img src="/images/product/daddy-sharp-shop.webp" alt="" className="w-2/3" />
              </div>
              <div className="w-full aspect-square bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-board-partner.webp')` }}>
                <img src="/images/product/sun.webp" alt="" className="w-2/3" />
              </div>
            </div>
          </div>
        </section>
        {/* Mobile */}
        <section
          className="w-full aspect-[9/16] bg-no-repeat bg-cover bg-center md:hidden relative pt-16 overflow-hidden"
          style={{ backgroundImage: `url('/images/labs/bg-mobile.webp')` }}
        >
          <div className="absolute inset-x-0 -top-px">
            <img src="/images/labs/divider.webp" alt="Divider" className="w-full" />
          </div>

          <div className="ml-9 w-48 aspect-[5/1] flex items-center justify-center font-cursive text-black bg-center bg-cover mb-4" style={{ backgroundImage: `url('/images/labs/bg-mobile-board-title.webp')` }}>
            Our Products
          </div>

          <div className="px-8">
            <div className="grid grid-cols-2 md:grid-cols-3 gap-5">
              <div className="w-full aspect-square bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-board-partner.webp')` }}>
                <img src="/images/product/khuga-nft.webp" alt="" className="w-2/3" />
              </div>
              <div className="w-full aspect-square bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-board-partner.webp')` }}>
                <img src="/images/product/miawwhalla.webp" alt="" className="w-2/3" />
              </div>
              <div className="w-full aspect-square bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-board-partner.webp')` }}>
                <img src="/images/product/khuga-bash.webp" alt="" className="w-2/3" />
              </div>
              <div className="w-full aspect-square bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-board-partner.webp')` }}>
                <img src="/images/product/khuga-rumble.webp" alt="" className="w-2/3" />
              </div>
              <div className="w-full aspect-square bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-board-partner.webp')` }}>
                <img src="/images/product/daddy-sharp-shop.webp" alt="" className="w-2/3" />
              </div>
              <div className="w-full aspect-square bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/labs/bg-board-partner.webp')` }}>
                <img src="/images/product/sun.webp" alt="" className="w-2/3" />
              </div>
            </div>
          </div>
        </section>

        {/* HQ */}
        {/* Desktop */}
        <section
          className="w-full aspect-[10/7] bg-no-repeat bg-cover bg-center hidden md:flex items-center justify-center relative"
          style={{ backgroundImage: `url('/images/labs/bg-hq.webp')` }}
        >
          <div className="absolute inset-x-0 -top-px">
            <img src="/images/labs/divider.webp" alt="Divider" className="w-full" />
          </div>
        </section>
        {/* Mobile */}
        <section
          className="w-full aspect-[9/16] bg-no-repeat bg-cover bg-center md:hidden flex items-center justify-center relative"
          style={{ backgroundImage: `url('/images/labs/bg-mobile-hq.webp')` }}
        >
          <div className="absolute inset-x-0 -top-px">
            <img src="/images/labs/divider.webp" alt="Divider" className="w-full" />
          </div>
        </section>
      </div>

      <Footer />
    </LayoutHome>
  );
}
