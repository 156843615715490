export default function Footer() {
  return (
    <footer className="w-full bg-black text-white font-marketplace h-14 flex items-center relative">
        <div className="absolute top-0 inset-x-0 h-px bg-gradient-to-r from-black via-white to-black animate-pulse"></div>
        <div className="container mx-auto px-4 text-center">
            <p>©2025 Khuga by Khuga Labs</p>
        </div>
    </footer>
  );
}
